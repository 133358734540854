import {
	Button,
	Toast,
	Popup
} from 'vant';


import {
	// getSign,
	voucherState
} from '@/api/index.js';

import { isWeiXin } from '@/utils/utils.js';

import bottom from '@/view/process/components/bottom.vue';
import localStore from 'storejs'; // localStorage的二次封装(可直接存储与获取对象)

export default {
	components: {
		[Button.name]: Button,
		[Popup.name]: Popup,
		bottom,
	},
	data() {
		return {
			l: '',
			unionid: '',
			path: '', // 页面路径: /login、/login_en
			lang: 'en', // 语言: zh、en
			style: {}, // 当前页面样式设置
      informationOptions: [],
			data: {}, // 回填数据
			btnColor: '#048DAD',
			logoSrc: require('@/assets/img/logo.png'),
			tag: '', // 入口来源: sms-通过短信链接访问
			isCamp: false, // 902、8102
			isCampNormal: false, // 8102
		}
	},
  destroyed() {
    this.timer && clearInterval(this.timer);
  },
	created() {
		Toast.clear(); // 清理弹出的toast信息
		
		this.tag = this.$route.query.u || ''; // 是否通过短信链接访问
    this.path = this.$route.path; // 当前页面路径

		// flowType登记类型: 1-预登记, 2-现场登记
		// lang语言: zh-中文, en-英文bgSrc
    // flowId: 流程id
		let { flowType = 1, lang = 'en', unionid = '', l = '', flowId} = localStore();
    this.isCamp = flowId == 902 || flowId == 8102;
    this.isCampNormal = flowId == 8102;
    this.setPageTitle(flowType); // 设置页面标题
    
		if (unionid) {
			// 获取本地存储的当前页面样式配置
			
			// 获取页面样式
			let styleName = this.path.includes('_en') ? 'cssEn' : 'css';
			this.style = localStore(styleName);
      		this.informationOptions = this.style.informationOptions || localStore('css').informationOptions;

			// 从临时缓存内获取页面回填数据
			// let data = sessionStorage.getItem('query');
			// data && (this.data = JSON.parse(data)[this.path] || {});

			this.unionid = unionid;
			this.l = l;
			this.lang = lang;
			this.flowId = flowId;

			// !this.path.includes('/pay') && this.validateSignUp(); // 校验是否已报名成功
			
			// this.hideMenu(); // 隐藏所有功能按钮
			
		} else {
      // 不是登记页面时，页面重定向
      if (!this.path.includes('/login')) {
        // 跳转到错误提示页面
        // this.$router.replace({
        //   path: '/errorTips',
        //   query: {
        //     errorMessage: '访问失败, 参数缺失 <br > Access failed, parameter missing'
        //   }
        // })
        
        this.$router.replace('/redirect');
      }
		}
		 
	},
	methods: {
		// 设置语言环境
		setLanguge(languge) {
			this.lang = languge;
			localStorage.setItem('lang', languge);
		},
		// 设置页面标题
		setPageTitle(flowType) {
			// 语言配置this.lang因先后顺序问题, 所以需要setTimeout延时取最后的配置
			setTimeout(() => {
        // 户外展海外H5，902（VIP）,8102（普通）
				if (flowType == 1) { // 预先登记
					// let tit_zh = this.isCamp ? '动力大湾生活节预先登记' : '深圳家居设计周预先登记';
					let tit_zh = this.isCamp ? 'DBF入场登记' : '深圳家居设计周暨深圳国际家具展';
					let tit_en = this.isCamp ? 'DBF Registration' : 'SZCW Pre-registration';
					document.title = this.lang == 'en' ? tit_en : tit_zh;
				} else { // 现场登记、vip登记
					// let tit_zh = this.isCamp ? '动力大湾生活节入场登记' : '深圳家居设计周暨深圳国际家具展';
					let tit_zh = this.isCamp ? 'DBF入场登记' : '深圳家居设计周暨深圳国际家具展';
					let tit_en = this.isCamp ? 'DBF Registration' : 'SZCW Registration';
					document.title = this.lang == 'en' ? tit_en : tit_zh;
				}
			})
		},
		// 校验是否已报名成功
		validateSignUp() {

			// 非登陆页面，需要校验是否已报名成功 (登录页: /login, /login_en)
			if (!this.path.includes('/login')) {
			
				voucherState().then(res => {

					let isMemberCode = this.path.includes('/memberCode'); // 是否会籍卡(登记成功)页面: /memberCode, /memberCode_en
					let isWaiting = this.path.includes('/waiting'); // 是否待审页面: /waiting, /waiting_en
					let isRealnameAuth = this.path.includes('/realnameAuth'); // 是否实名校验(身份验证)页面: /realnameAuth, /realnameAuth_en
					
					if (!res.data.state || res.data.state == 0) { // 未报名成功时
					
						// 会籍卡页时, 根据是否存在参数，跳转到相应页面
						if (isMemberCode) {
							setTimeout(() => {
								// 清理定时器
								this.timer && clearInterval(this.timer);
							})
							if (this.unionid && this.l) {
								this.$router.replace({
									path: '/auth',
									query: {
										unionid: this.unionid,
										l: this.l
									}
								})
							} else {
								this.$router.replace({
									path: '/errorTips',
									query: {
										errorMessage: this.lang = 'en' ? 'Missing parameter, <br> unable to access membership card' : '缺失参数, 不能访问会籍卡！'
									}
								})
							}
						}
					} else {
						if (res.data.state == 1) {  // 报名成功时: 进入会籍卡页面
							
							// 非会籍卡页时跳转到会籍卡页
							!isMemberCode && this.$router.replace(this.lang === 'en' ? '/memberCode_en' : '/memberCode');
							
						} else if (res.data.state == 2) { // 处于审核状态		
							
							if (res.data.id_card_check_state == 0) { // 待审: 进入待审页面
							
								// 非待审页时跳转到待审页
								!isWaiting && this.$router.replace(this.lang === 'en' ? '/waiting_en' : '/waiting');
								
							} else if (res.data.id_card_check_state == 2) { // 审核不通过: 进入实名校验页面
							
								// 非实名校验页时跳转到实名校验页
								!isRealnameAuth && this.$router.replace(this.lang === 'en' ? '/realnameAuth_en' : '/realnameAuth');
								
							}
							
						}
						
					}

				})
			}
		},
		// 页面转换
		pageSwitch(res) {
			// if (res.css && res.h5Url) {
			if (res.h5Url) {
				let params = {};
				switch (res.h5Url.split('_')[0]) {
					case '/realnameAuth':
						params = res.data.idCard || {};
						break;
					case '/industrySelect':
						params = res.data.trade || {};
						break;
					case '/question':
						params = res.data.question || {};
						break;
					case '/shootCard':
						params = res.data.cardInfoVo || {};
						break;
					case '/information':
						params = res.data.userInfo || {};
						break;
					case '/memberCode':
						params = {};//res.data.voucher || {};
						break;
				}
				this.setPageData(res.h5Url, params); // 更新临时缓存内指定页面的回填数据
				
				// 本地存储下一页的样式配置(中、英)
				localStore.set('css', res.css);
				localStore.set('cssEn', res.cssEn);
				
				// 判断是否会籍卡页面
				if (res.h5Url.includes('/memberCode')) {
					this.$router.replace(res.h5Url)
				} else {
					this.$router.push(res.h5Url)
				}
			}
		},
		// 更新临时缓存内指定页面的回填数据 setPageData
		setPageData(path, obj) {
			let query = sessionStorage.getItem('query');
			query = query ? JSON.parse(query) : {};

			query[path] = obj;
			sessionStorage.setItem('query', JSON.stringify(query));
		},
		// 获取授权签名
		// async getTencentSign() {
		// 	let timestamp = Date.now(),
		// 		sign = sessionStorage.getItem('sign'),
		// 		signTimeOut = sessionStorage.getItem('signTimeOut');

		// 	// 校验sign与超时设置
		// 	if (sign && sign != '{}' && signTimeOut && signTimeOut > timestamp) {
		// 		return JSON.parse(sign);
		// 	} else {
		// 		try {
		// 			let sign = await getSign();
		// 			if (sign && sign.code == 200) {
		// 				sessionStorage.setItem('sign', JSON.stringify(sign.data));
		// 				sessionStorage.setItem('signTimeOut', timestamp + 1000 * 60 * 60 * 2 - 10000); // 超时: 2小时后(少10秒)
		// 				return sign.data
		// 			} else {
		// 				return null
		// 			}
		// 		} catch (err) {
		// 			return null
		// 		}
		// 	}
		// },
		// 隐藏分享按钮
		// async hideMenu() {
			
		// 	// 如果是微信浏览器
		// 	if (isWeiXin()) {
		// 		let signObj = await this.getTencentSign();
		// 		// signObj: {
		// 		// 	sign: "ef0c4caee4e40afedeacd7feb18449337b6fee89", 
		// 		// 	jsapi_ticket: "bxLdikRXVbTPdHSM05e5u1-0R5PPT0smbPfQ2mI1FtKA8E3L26LuGchYssnZsn5zZ1GTK874pgLl_0jZvKhQUQ", 
		// 		// 	noncestr: "SZCW", 
		// 		// 	url: "https://reg36.szcreativeweek.com/h5/code", 
		// 		// 	timestamp: "1608603104",
		// 		// }

		// 		if (!signObj) return;

		// 		const wx = require('weixin-js-sdk');
		// 		wx.config({
		// 			debug: false, // 开启调试模式,所有api返回会alert出来，要查看传入的参数，可在pc端打开，参数信息会log打出，仅在pc端时才会打印。
		// 			appId: 'wxcfaadd75fad331e9', // 必填，公众号的唯一标识
		// 			timestamp: signObj.timestamp, // 必填，生成签名的时间戳
		// 			nonceStr: signObj.noncestr || 'SZCW', // 必填，生成签名的随机串
		// 			url: signObj.url.split('/').slice(0, 3).join('/'), // https://reg36.szcreativeweek.com
		// 			signature: signObj.sign, // 必填，签名
		// 			jsApiList: [ // 必填，需要使用的JS接口列表
		// 				'checkJsApi',
		// 				'onMenuShareTimeline',
		// 				'onMenuShareAppMessage',
		// 				'hideMenuItems',
		// 				'hideOptionMenu'
		// 			]
		// 		})
		// 		wx.ready(function() { //需在用户可能点击分享按钮前就先调用
		// 			wx.hideOptionMenu()
		// 			wx.checkJsApi({
		// 				jsApiList: [
		// 					'checkJsApi',
		// 					'onMenuShareTimeline',
		// 					'onMenuShareAppMessage',
		// 					'hideMenuItems',
		// 					'hideOptionMenu'
		// 				]
		// 			})
		// 		})
		// 	}
			
		// },
		
	},
}
